
export const IsDebug = false;
export const TestServerSession = "9BB0681FCCCB2CD793F5F9C0BED2DEE6";

export const ServerTest = "";
export const PageTest = "";

// const ServerTest = "test/";
// const PageTest = ":8091";

// export const ServerHost = "http://localhost:5000/";						// 用户本地测试模式
export const ServerHost = `https://wx.member.api.gxuafa.cn/${ServerTest}`;	// 用于部署服务器
export const PageHost = `https://wx.member.gxuafa.cn${PageTest}/`;			// 用于前端页面
export const UserImgPath = "static/user-image/";							// 用户上传的个人照片地址

export const GameName = "2021广西大学校友足球联赛";

export const UrlPath = {
	User: {
		LoginWithSession: "user/session",
		Register: "user/register",
		EditInfoUrl: "user/edit-info",
		GetAuthority: "user/authority",
		Management: "user/management",
		ManageMember: "user/member-accept",
		GetTimeLimit: "user/time-limit",
		UploadImage: "user/upload-image",
		MemberPay: "user/member-pay",
		GetUserPayInfo: "user/check-member-pay",
	},

	Game: {
		GetGames: "game/games",
		GetOneGame: "game/one-game",
		AddTeam: "game/add-team",
		SignUp: "game/sign-up",
		CheckSignUp: "game/check-sign-up",
		GamePay: "game/sign-up-pay",
		UserSignUpGames: "game/user-sign-up",
		GetTeammate: "game/get-teammate",
		GetMyLicence: "game/get-licence"
	},

	Suggestion: {
		Submit: "suggestion/submit"
	},

	Common: {
		GetAccessToken: "common/use-jssdk",
	},

	GetDictionary: "mydictionary/get-dic",
	GetLocationInfo: "mydictionary/get-location"
}

export const WxAppId = "wx30cbd7b23806418a";
export enum WxMiniRouter {
	Pay = "../Pay/Pay",
	LoginIndex = "../index/index",
	UtilPage = "../UtilPage/UtilPage"
}

export interface ResponseResult<T> {
	result: "success" | "failed";
	reason?: string;
	data: T;
}

export enum Authority {
	TeamLeader = "teamLeader"
}
