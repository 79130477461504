import { User, UserEx } from '@/models/User'
import { createStore } from 'vuex'

export default createStore({
	state: {
		User: <User | undefined>undefined,
		UserEx: <UserEx | undefined>undefined
		// UserInfo: {
		// 	name: "梅西",
		// 	gender: 1005,
		// 	team: 1008,
		// 	school: 1001,
		// 	graduates: 2000,
		// 	location: "柳州分会",
		// 	province: 1014,
		// 	city: 1016
		// },

	},
	mutations: {
	},
	actions: {
	},
	modules: {
	}
})
