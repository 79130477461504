<template>
	<div class="main-panel">
		<router-view />
	</div>

	<!-- <van-tabbar v-model="active">
		<van-tabbar-item name="home" icon="home-o" @click="ButtonAction(0)">主页</van-tabbar-item>
		<van-tabbar-item name="user" icon="user-o" @click="ButtonAction(1)">我的</van-tabbar-item>
	</van-tabbar> -->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { IsDebug, TestServerSession } from "@/models/Const";
import { LoginCallbackParams } from "@/models/Interface";
import { Utils } from "./models/Utils";
import { UserUtils } from "./models/User";
import store from "./store";
import { RouterName } from "./router";
import { RouteLocationNormalized } from "node_modules/vue-router/dist/vue-router";

export default defineComponent({
	setup() {
		const active = ref("home");
		return { active };
	},
	beforeCreate() {
		let data: any;
		if (IsDebug) {
			// 可用直接获取 serverSession作为获取用户信息的测试入口
			data = { serverSession: TestServerSession };
		} else {
			data = <LoginCallbackParams>Utils.GetLinkData();
			if (!data) return;
		}

		UserUtils.Login({ server_session: data.serverSession }).then(
			(value) => {
				if (value) {
					store.state.User = value.user;
					store.state.UserEx = value.userEx;
					this.$router.push({
						name: RouterName.User.UserCenter.name,
					});
				}
			}
		);
	},
	methods: {
		ButtonAction(index: number) {
			switch (index) {
				case 0:
					this.$router.push({ name: RouterName.HomePage.name });
					break;
				case 1:
					this.$router.push({ name: RouterName.User.UserCenter.name });
					break;
			}
		},
	},
	watch: {
		$route(to: RouteLocationNormalized, from: RouteLocationNormalized) {
			if (to.name == RouterName.HomePage.name) {
				this.active = "home";
			} else {
				this.active = "user";
			}
		}
	}
});
</script>

<style lang="scss" scoped>
@import "@/assets/global.scss";

.main-panel {
	overflow: auto;
	width: 100%;
	position: absolute;
	top: 0px;
	bottom: $bottomToolsHeight;
}
</style>
