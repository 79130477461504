import axios from "axios";
import { ResponseResult, ServerHost, UrlPath } from "./Const";

export const MyAxios = axios.create({
	baseURL: ServerHost,
	//baseURL: "http://localhost:8000/",
	timeout: 10000,
	headers: {
		"Content-Type": "application/json"
	}
})

/**工具类 */
export class Utils {

	//#region 获取链接里的数据
	/**获取链接里的数据 */
	static GetLinkData() {
		let data = window.location.search.substring(1);
		if (!data || data.trim() == "")
			return undefined;

		let temp = data.split("&");
		let result: any = {};
		for (let i = 0; i < temp.length; i++) {
			let temp1 = temp[i].split("=");
			result[temp1[0]] = temp1[1];
		}
		return result;
	}
	//#endregion 获取链接里的数据

	//#region 转换成FormData
	/**
	 * 转换成FormData
	 * @param data 要转换的数据
	 * @returns 
	 */
	static GetRequestData(data: any) {
		let formData = new FormData();
		for (let key in data) {
			if (data[key] == undefined)
				continue;

			formData.append(key, data[key]);
		}
		console.log(formData);
		return formData;
	}
	//#endregion 转换成FormData

	//#region 将数据放入Url
	/**
	 * 将数据放入Url
	 * @param url url地址
	 * @param data 数据
	 * @returns 拼接好的Url
	 */
	static PushDataToUrl(url: string, data: any) {
		if (!data)
			return url;

		let temp = "?";
		for (let key in data) {
			if (data[key] == undefined)
				continue;

			temp += `${key}=${encodeURIComponent(data[key])}&`
		}
		url += temp.substring(0, temp.length - 1)
		return url;
	}
	//#endregion 将数据放入Url

	//#region 深度拷贝
	/**
	 * 深度拷贝
	 * @param source 要拷贝的对象
	 * @returns 拷贝的对象
	 */
	static DeepClone<T>(source: any): T {
		const targetObj: any = source.constructor === Array ? [] : {}; // 判断复制的目标是数组还是对象
		for (let keys in source) { // 遍历目标
			if (source.hasOwnProperty(keys)) {
				if (source[keys] && typeof source[keys] === 'object') { // 如果值是对象，就递归一下
					targetObj[keys] = source[keys].constructor === Array ? [] : {};
					targetObj[keys] = Utils.DeepClone(source[keys]);
				} else { // 如果不是，就直接赋值
					targetObj[keys] = source[keys];
				}
			}
		}
		return targetObj;
	}
	//#endregion 深度拷贝

	//#region 时间格式化
	/**时间格式化 yyyy-MM-dd HH:mm:ss */
	static DateFormat(date: Date, format: string) {
		let ret;
		const opt = {
			"y+": date.getFullYear().toString(),        // 年
			"M+": (date.getMonth() + 1).toString(),     // 月
			"d+": date.getDate().toString(),            // 日
			"H+": date.getHours().toString(),           // 时
			"m+": date.getMinutes().toString(),         // 分
			"s+": date.getSeconds().toString()          // 秒
			// 有其他格式化字符需求可以继续添加，必须转化成字符串
		};
		for (let k in opt) {
			ret = new RegExp("(" + k + ")").exec(format);
			if (ret) {
				// @ts-ignore
				format = format.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
			};
		};
		return format;
	}
	//#endregion 时间格式化

	//#region 将时间 yyyy-MM-dd HH:mm:ss 转换成 Date
	/**将时间 yyyy-MM-dd HH:mm:ss 转换成 Date */
	static DateStringConvert(dateString: string) {
		if (!dateString)
			return null;

		let date = new Date();
		date.setMilliseconds(0);
		// @ts-ignore
		date.setFullYear(dateString.substring(0, 4));
		// @ts-ignore
		date.setMonth(dateString.substring(5, 7) - 1);
		// @ts-ignore
		date.setDate(dateString.substring(8, 10));

		if (dateString.length >= 13)
			// @ts-ignore
			date.setHours(dateString.substring(11, 13));

		if (dateString.length >= 16)
			// @ts-ignore
			date.setMinutes(dateString.substring(14, 16));

		if (dateString.length >= 17)
			// @ts-ignore
			date.setSeconds(dateString.substring(17));

		return date;
	}
	//#endregion 将时间 yyyy-MM-dd HH:mm:ss 转换成 Date

	//#region 上传文件
	/**上传文件 */
	static async UploadFile(url: string, formData: FormData) {
		let result = await axios.post<ResponseResult<boolean>>(url, formData, {
			headers: { 'Content-Type': 'multipart/form-data' }
		});
		if (result.data.result != "success") {
			console.log(result.data.reason);
		}

		return result.data.data;
	}
	//#endregion 上传文件

	//#region 用户表单检验数字
	/**用户表单检验数字 */
	static CheckNumber(rule: any, value: any, errorMsg: string) {
		return new Promise<void>((resolve, reject) => {
			let temp = parseInt(value);
			if (isNaN(temp)) {
				reject(errorMsg);
			} else {
				resolve();
			}
		})
	}
	//#endregion 用户表单检验数字

}