import store from "@/store";
import { UrlPath, ResponseResult } from "./Const";
import { MyAxios, Utils } from "./Utils";

/**用户类 */
export class User {
	name: string = "";
	avatar: string = "";
	gender: number = 0;
	server_session: string = "";
	authority: number | null = null;
	regist_time: string | null = null;
}

/**用户扩展接口 */
export interface UserEx {
	/**用户公开的ID */
	user_public_id: number;
	/**用户基本信息 */
	user_info: UserInfo;
	/**用户照片路径 */
	photo: string;
	/**会员激活时间 */
	member_active_time: string;
	/**激活时缴费的订单号 */
	order_id: string;
}

/**用户信息 */
export class UserInfo {
	name: string | null = null;
	gender: number | null = null;
	birthday: Date | null = null;
	party: string | null = null;
	province: number | null = null;
	city: number | null = null;
	school: string | null = null;
	college: string | null = null;
	major: string | null = null;
	enrolmentDate: number | null = null;
	personId: string | null = null;
	height: number | null = null;
	weight: number | null = null;
	bloodType: string | null = null;
	phone: string | null = null;
	comment: string | null = null;
}

/**用户参赛信息 */
export class UserGameInfo {
	height: number | null = null;
	weight: number | null = null;
	clothes_size: string | null = null;
	clothes_number: number | null = null;
	position: string | null = null;
	phone: string | null = null;
	comment: string | null = null;
}

export class UserUtils {

	//#region 登录
	static async Login(option: { server_session: string }) {
		let result = await MyAxios.post<ResponseResult<{ user: User, userEx: UserEx }>>(UrlPath.User.LoginWithSession, option);
		if (result.data.result != "success") {
			console.error(result.data.reason);
			return null;
		}
		if (result.data.data.userEx?.user_info?.birthday)
			// @ts-ignore
			result.data.data.userEx.user_info.birthday = Utils.DateStringConvert(result.data.data.userEx.user_info.birthday);

		return result.data.data;
	}
	//#endregion 登录

	//#region 注册信息提交
	/**注册信息提交 */
	static async Register(option: { user_info: UserInfo, timeLimitInvite?: boolean }) {
		// @ts-ignore
		option.server_session = store.state.User?.server_session;
		// @ts-ignore
		option.user_info.birthday = Utils.DateFormat(option.user_info.birthday, "yyyy-MM-dd");
		let result = await MyAxios.post<ResponseResult<{ userEx: UserEx, option?: any }>>(UrlPath.User.Register, option);
		if (result.data.result != "success") {
			console.error(result.data.reason);
			return null;
		}
		return result.data;
	}
	//#endregion 注册信息提交

	//#region 编辑用户信息
	/**编辑用户信息 */
	static async EditUserInfo(option: { user_info: UserInfo }) {
		// @ts-ignore
		option.server_session = store.state.User?.server_session;
		// @ts-ignore
		option.user_info.birthday = Utils.DateFormat(option.user_info.birthday, "yyyy-MM-dd");
		let result = await MyAxios.post<ResponseResult<{ userInfo: UserInfo }>>(UrlPath.User.EditInfoUrl, option);
		if (result.data.result != "success") {
			console.error(result.data.reason);
			return;
		}
		return result.data;
	}
	//#endregion 编辑用户信息

	//#region 上传用户的照片
	/**上传用户的照片 */
	static async UploadUserImage(option: { photo: File }) {
		let formData = new FormData();
		formData.append("server_session", <string>store.state.User?.server_session);
		if (option.photo)
			formData.append("photo", option.photo);

		let result = await MyAxios.post<ResponseResult<string>>(UrlPath.User.UploadImage, formData, {
			headers: { 'Content-Type': 'multipart/form-data' }
		});

		if (result.data.result != "success") {
			return null;
		}

		return result.data.data;
	}
	//#endregion 上传用户的照片

	//#region 检查用户权限
	/**检查用户权限 */
	static async CheckUserAuthority(...authority: string[]) {
		let option: any = { server_session: store.state.User?.server_session, authority: authority };
		let result = await MyAxios.post<ResponseResult<boolean>>(UrlPath.User.GetAuthority, option);
		if (result.data.result != "success") {
			console.error(result.data.reason);
			return null;
		}

		return result.data.data;
	}
	//#endregion 检查用户权限

	//#region 获取用户管理权限
	/**获取用户管理权限 */
	static async GetManageUsers() {
		let option = { server_session: store.state.User?.server_session };
		let result = await MyAxios.post<ResponseResult<{ user: User, userEx: UserEx }[]>>(UrlPath.User.Management, option);
		if (result.data.result != "success") {
			return [];
		}

		return result.data.data;
	}
	//#endregion 获取用户管理权限

	//#region 审批用户
	static async ManageUser(option: { user_public_id: number, reply: boolean }) {
		// @ts-ignore
		option.server_session = GlobalVar.serverSession;

		let result = await MyAxios.post<ResponseResult<boolean>>(UrlPath.User.ManageMember, JSON.stringify(option));
		debugger;
		if (result.data.result != "success") {
			return false;
		}

		return true;
	}
	//#endregion 审批用户

	//#region 获取时限
	static async GetTimeLimit() {
		let result = await MyAxios.get<ResponseResult<string>>(UrlPath.User.GetTimeLimit);
		if (result.data.result != "success") {
			console.error(result.data.reason);
			return null;
		}

		return result.data.data;
	}
	//#endregion 获取时限

	//#region 检查用户是否已经支付
	/**检查用户是否已经支付 */
	static async CheckUserPay() {
		let option = { server_session: store.state.User?.server_session };
		let result = await MyAxios.post<ResponseResult<{ memberActiveTime: string, orderId: string, startTime: string }>>(UrlPath.User.GetUserPayInfo, option);
		if (result.data.result != "success") {
			console.error(result.data.reason);
			return false;
		}

		if (result.data.data == null)
			return false;

		let time = Utils.DateStringConvert(result.data.data.memberActiveTime);
		let startTime = Utils.DateStringConvert(result.data.data.startTime);
		if (time == null || startTime == null)
			return false;

		let timeSpan = time.getTime() - startTime.getTime();
		return timeSpan >= 0;
	}
	//#endregion 检查用户是否已经支付

}