import store from '@/store';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

export const RouterName = {
	/**主页 */
	HomePage: { path: "/", name: "home" },
	User: {
		/**用户主页 */
		UserCenter: { path: "/user", name: "user" },
		/**用户信息编辑 */
		EditUserInfo: { path: "/user/edit-info", name: "editInfo" },
		/**支付页面 */
		MemberPay: { path: "/user/member-pay", name: "memberPay" },
		/**用户参赛信息 */
		UserGameInfo: { path: "/user/user-game-info", name: "userGameInfo" },
	},
	/**注册页面 */
	Register: { path: "/register", name: "register" },
	/**会员管理 */
	MemberManage: { path: "/management", name: "management" },

	Game: {
		/**联赛管理 */
		AllGames: { path: "/all-game", name: "all-game" },
		/**比赛报名 */
		JoinGame: { path: "/game-sign-up", name: "game-sign-up" },
		MyJoinGame: { path: "/my-games", name: "my-games" },
		MyGameLicence: { path: "/my-game-licence", name: "my-game-licence" }
	},

	Suggestion: {
		Suggestion: { path: "/suggestion", name: "suggestion" }
	}
}

const routes: Array<RouteRecordRaw> = [
	{ path: RouterName.HomePage.path, name: RouterName.HomePage.name, component: () => import("@/components/Home/Home.vue") },

	{ path: RouterName.Game.AllGames.path, name: RouterName.Game.AllGames.name, component: () => import("@/components/Game/GameList.vue") },
	{ path: RouterName.Game.JoinGame.path, name: RouterName.Game.JoinGame.name, component: () => import("@/components/Game/GameSignUp.vue") },
	{ path: RouterName.Game.MyJoinGame.path, name: RouterName.Game.MyJoinGame.name, component: () => import("@/components/Game/MyGameSignUp.vue") },
	{ path: RouterName.Game.MyGameLicence.path, name: RouterName.Game.MyGameLicence.name, component: () => import("@/components/Game/GameLicence.vue") },

	{ path: RouterName.Register.path, name: RouterName.Register.name, component: () => import("@/components/UserCenter/UserInfo.vue") },

	{ path: RouterName.User.UserCenter.path, name: RouterName.User.UserCenter.name, component: () => import("@/components/UserCenter/UserCenter.vue") },
	{ path: RouterName.User.MemberPay.path, name: RouterName.User.MemberPay.name, component: () => import("@/components/UserCenter/MemberPay.vue") },
	{ path: RouterName.User.EditUserInfo.path, name: RouterName.User.EditUserInfo.name, component: () => import("@/components/UserCenter/UserInfo.vue") },

	{ path: RouterName.Suggestion.Suggestion.path, name: RouterName.Suggestion.Suggestion.name, component: () => import("@/components/Suggestion/Suggestion.vue") },
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	if (!store.state.UserEx && to.name != RouterName.HomePage.name && to.name != RouterName.Register.name) {
		next({ name: RouterName.HomePage.name });
	} else {
		next();
	}
});

export default router
