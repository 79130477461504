
import { defineComponent, ref } from "vue";
import { IsDebug, TestServerSession } from "@/models/Const";
import { LoginCallbackParams } from "@/models/Interface";
import { Utils } from "./models/Utils";
import { UserUtils } from "./models/User";
import store from "./store";
import { RouterName } from "./router";
import { RouteLocationNormalized } from "node_modules/vue-router/dist/vue-router";

export default defineComponent({
	setup() {
		const active = ref("home");
		return { active };
	},
	beforeCreate() {
		let data: any;
		if (IsDebug) {
			// 可用直接获取 serverSession作为获取用户信息的测试入口
			data = { serverSession: TestServerSession };
		} else {
			data = <LoginCallbackParams>Utils.GetLinkData();
			if (!data) return;
		}

		UserUtils.Login({ server_session: data.serverSession }).then(
			(value) => {
				if (value) {
					store.state.User = value.user;
					store.state.UserEx = value.userEx;
					this.$router.push({
						name: RouterName.User.UserCenter.name,
					});
				}
			}
		);
	},
	methods: {
		ButtonAction(index: number) {
			switch (index) {
				case 0:
					this.$router.push({ name: RouterName.HomePage.name });
					break;
				case 1:
					this.$router.push({ name: RouterName.User.UserCenter.name });
					break;
			}
		},
	},
	watch: {
		$route(to: RouteLocationNormalized, from: RouteLocationNormalized) {
			if (to.name == RouterName.HomePage.name) {
				this.active = "home";
			} else {
				this.active = "user";
			}
		}
	}
});
