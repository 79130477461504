interface WxJSSDKConfigParams {
	nonceStr: string;
	timestamp: number;
	signature: string;
}

export class GlobalVar {
	static Init() {
		wx.miniProgram.getEnv((res) => {
			console.log(res.miniprogram);
		});
	}
}